.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  width: 100%;
}

html, body {
  overflow-x: clip;
  max-width: 100vw;
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  display: grid;
  place-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: -0.313rem;
  z-index: 2;
  height: 4.375rem;
  min-height: 4.375rem;
  width: calc(100%);
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}


.nav__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav__container__actions {
  display: inline-flex;
  flex-flow: row nowrap;
  padding-left: 5rem;
  padding-right: 5rem;
  flex: 1;
}

.active {
  font-weight: bold;
  color: #2B3467;
}

ul {
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

li {
  cursor: pointer;
}

li:hover {
  text-decoration:wavy ;
}

.logo-image {
  width: 3rem;
  height: auto;
}

.home-header {
  display: flex;
  align-items: center;
}

.home-body {
  height: 100vh;
  padding-top: 10rem;
}

/* .item-section {
  padding-top: 2rem;
  align-content: flex-start;
} */

#reach-me-btn {
  margin: 0.5rem;
  padding: 0.5rem;
  flex-shrink: 0;
  background: #2B3467;
  cursor: pointer;
}

#reach-me-btn-label {
  color: white;
}

.reach-me-btn-box {
  width: 1rem;
  height: 1rem;
  background-color: #EB455F;
  cursor: pointer;
}

#reach-me-btn-box-left {
  margin-top: -0.5rem;
  float: left;
}

#reach-me-btn-box-right {
  margin-top: -1rem;
  float: right;
}

#about-me-title {
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: left;
  align-self: flex-start;
  font-size: larger;
  font-weight: bold;
  color: #2B3467;
}

.about-me-detail {
  padding-left: 5rem;
  padding-right: 5rem;
  font-weight: normal;
  align-items: flex-start;
  text-align: left;
  align-self: flex-start;
}

.item-section {
  padding-top: 5rem;
  align-items: flex-start;
  width: 100%;
}

.hStackContainer {
  display: flex;
  padding-right: 0.5rem;
  text-align: left;
}

.circle {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  
  aspect-ratio: 1/1;
  background-color: #EB455F;
}

.year-header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 2rem;
  color: #2B3467;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.type-header {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1.25rem;
  color: #2B3467;
}

.detail-inline {
  display: flex;
}

.detail-body {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1.25rem;
  color: #2B3467;
  padding-right: 0.25rem;
}

.grade-body {
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.25rem;
  color: #2B3467;
}

.name-body {
  font-family: "Inter";
  font-weight: 700;
  font-size: 1.25rem;
  color: #2B3467;

}

.list-template-line {
  width: 0.5rem;
  height: 100%;
  background-color: #EB455F;
}

.list-template-body {
  display: grid;
  grid-template-columns: auto; 
  text-align: center;
}

.list-template-data {
  display: grid;
  
}

.section-header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 3rem;
  justify-content: center;
  color: #2B3467;
}

.section-detail {
  text-align: left;
  display: grid;
  justify-items: left;
  min-width: 100vw;
  margin: auto;
  padding-left: 5rem;
  padding-right: 5rem;
}

.about-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.section-title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 2.5rem;
  color: #EB455F;
}

.chip-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.chip-item {
  margin-right: 0.5rem;
}

.contact-body {
  height: 100vh;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.contact-logo-button {
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
}

.contact-social-media {
  align-items: center;
  justify-content: center;
}

.contact-social-media-separator {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #2B3467;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.contact-header {
  font-family: "Inter";
  font-size: 4.5rem;
  font-weight: 700;
  color: #2B3467;
}

.contact-subheader {
  font-family: "Inter";
  font-size: 4.5rem;
  font-weight: 700;
  color: #EB455F;
}

.contact-detail {
  font-family: "Inter";
  font-size: 2.25rem;
  font-weight: 700;
  color: #2B3467;
}

@media only screen and (hover: none) and (pointer: coarse) {

  .section-nav {
    visibility: hidden;
  }

  .hamburger {
    visibility: visible;
  }

  .logo-nav {
    justify-content:  space-around;
    padding-left: 2rem;

  }
}

.section-body {
  max-width: 100vw;
}

.hamburger {
  flex-direction: column;
  cursor: pointer;
  margin-top: 1rem;
  visibility: hidden;
}

.line {
  height: 0.125rem;
  width: 1.563rem;
  background-color: #2B3467;
  margin: 0.313rem 0;
}

.past-project {
  width: 100%;
}